<template>
  <div>
    <v-row>
      <v-col>
        <RadiusIPChange :pppoeUsers="pppoeUsers"></RadiusIPChange>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Radacct :pppoeUsers="pppoeUsers" :standalone="true"></Radacct>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RadiusIPChange from "../../../components/radius/RadiusIPChange";
import Radacct from "../../../components/radius/Radacct";
import { API } from "../../../api";

export default {
  name: "radius.ipchanger.index",
  components: {
    RadiusIPChange,
    Radacct
  },
  data: () => ({
    pppoeUsers: []
  }),
  mounted: async function() {
    this.$store.commit("app/setTitle", "Radius felhasználók");

    await API.instance
      .get("/radius/user")
      .then(response => {
        this.pppoeUsers = response.data;
      })
      .catch(err => this.store.dispatch("messages/addText", err));
  }
};
</script>
