<template>
    <v-card>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar
            v-model="snackbar"
            multi-line
            timeout="3000"
            top
        >
            {{ snackbarText }}
        </v-snackbar>

        <v-card-title>Szimpla IP módosító</v-card-title> 
        <v-card-text>
            <v-row>
                <v-col>
                    <validation-observer
                        ref="observer"
                        v-slot="{ invalid }"
                    >
                        <form @submit.prevent="onSubmit" @keypress.enter.prevent>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="PPPOE felhasználó"
                                    :rules="{required: true} "
                                >
                                    <v-autocomplete
                                        :items=pppoeUsers
                                        @change="onPPPOEUsernameChange"
                                        item-text="Username"
                                        v-model="selectedPPPOEUser"
                                        return-object
                                        placeholder="Felhasználó"
                                        :error-messages="errors"
                                    >
                                    </v-autocomplete>
                                </validation-provider>
                                <div v-if="!selectedPPPOEUser['Pool-Name']">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="IP Pool"
                                        :rules="{required: true}"
                                    >
                                        <v-autocomplete
                                            placeholder="IP Pool"
                                            :items=ipPools
                                            @change="onIpPoolChange"
                                            v-model="selectedIPPool"
                                            item-text="description"
                                            item-value="id"
                                            :error-messages="errors"
                                        >
                                        </v-autocomplete>
                                    </validation-provider>

                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="IP Cím"
                                        :rules="{required: true, ucrmip: true}"
                                    >
                                        <v-autocomplete
                                            placeholder="IP Cím"
                                            :items="poolFreeIPS"
                                            v-model="selectedIP"
                                            :error-messages="errors"
                                        >
                                        </v-autocomplete>
                                    </validation-provider>
                                    <v-btn
                                        :disabled="invalid"
                                        color="primary"
                                        type="submit"
                                        class="mr-4"
                                    >Mentés</v-btn>
                                </div>
                                <div v-else >
                                    TODO: Jelen pillanatban nincs felkészítve az UCRM dinamikus IP-kre.
                                    <v-simple-table dense>
                                        <thead>
                                            <tr>
                                                <th>Tulajdonság</th>
                                                <th>Érték</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>Dinamikus pool neve:</b></td>
                                                <td>{{ selectedPPPOEUser["Pool-Name"] }}</td> 
                                            </tr>
                                            <tr> 
                                                <td><b>Jelenlegi IP:</b></td>
                                                <td>{{ selectedPPPOEUser["Framed-IP-Address"]}}</td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table> 
                                
                                </div>
                            </form>
                        </validation-observer>

                        
                </v-col>
                <v-col>
                    <v-simple-table dense>
                        <thead>
                            <tr>
                                <th>Tulajdonság</th>
                                <th>Érték</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>IP Cím:</b></td> 
                                <td>{{ selectedPPPOEUser["Framed-IP-Address"]}}</td>
                            </tr>
                            <tr>
                                <td><b>MS-Primary-DNS-Server:</b></td>
                                <td>{{ selectedPPPOEUser["MS-Primary-DNS-Server"]}}</td> 
                            </tr>
                            <tr>
                                <td><b>MS-Secondary-DNS-Server:</b></td>
                                <td>{{ selectedPPPOEUser["MS-Secondary-DNS-Server"] }}</td>
                            </tr>
                            <tr>
                                <td><b>Session-Timeout:</b></td>
                                <td>{{ selectedPPPOEUser["Session-Timeout"]}}</td>
                            </tr>
                            <tr>
                                <td><b>UCRM ügyfélazonosító:</b></td>
                                <td><a target="_blank" :href="'https://ucrm.keviwlan.hu/client/' + ucrmContract.client_id">{{ ucrmContract.client_id }}</a></td>
                            </tr>

                            <tr> 
                                <td><b>UCRM végpont:</b></td>
                                <td><a target="_blank" :href="'https://ucrm.keviwlan.hu/client/service/' + ucrmContract.service_id">{{ ucrmContract.service_id }}</a></td>
                            </tr>
                            <tr> 
                                <td><b>UCRM attr PPPOE MAC:</b></td>
                                <td>{{ ucrmContract.mac }}</td>
                            </tr>
                            <tr> 
                                <td><b>UCRM attr PPPOE IP:</b></td>
                                <td style="background-color: red;" v-if="ucrmContract.contract_ip != selectedPPPOEUser['Framed-IP-Address']">{{ ucrmContract.contract_ip }}</td>
                                <td v-else>{{ ucrmContract.contract_ip }}</td>
                            </tr>                            
                            <tr> 
                                <td><b>UCRM végpont eszköz IP:</b></td>
                                <td style="background-color: red;" v-if="ucrmContract.service_ip_address != selectedPPPOEUser['Framed-IP-Address']">{{ ucrmContract.service_ip_address }}</td>
                                <td v-else>{{ ucrmContract.service_ip_address }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text> 
    </v-card>
</template>


<script>
import {API} from '../../api/'

export default {
    name: 'RadiusIPChange',
    props: ["pppoeUsers"],
    data: () => ({
        loading: true,
        ipPools: [],
        // pppoeUsers: [],
        selectedPPPOEUser: {},
        selectedIPPool: null,
        selectedIP: null,
        poolFreeIPS: [],
        submitDisabled: false,
        snackbar: false,
        snackbarText: null,
        ucrmContract: {},
    }),
    mounted: async function(){
        API.instance.get('/radius/pool')
            .then((response) => {
                this.ipPools = response.data
            })
            .catch((err) => this.$store.dispatch('messages/addText', err))
            .finally(() => this.loading = false)
    },
    methods: {
        onIpPoolChange: async function(){
            return await API.instance.get(`/radius/pool/${this.selectedIPPool}`)
                .then((response) => {
                    this.poolFreeIPS = response.data
                    this.selectedIP = null
                })
                .catch((err) => this.$store.dispatch('messages/addText', err))
        },
        getUcrmContractInfo: async function(){
            this.ucrmContract = {}
            return await API.instance.get(`/ucrm/contract/${this.selectedPPPOEUser.Username}`)
                .then((response) => this.ucrmContract = response.data)
                .catch((err) => {this.$store.dispatch('messages/addText', err)})
        },
        onPPPOEUsernameChange: function(){
            // Ki kell törölni a hibákat.
            this.$store.dispatch('messages/clearMessages')
            this.loading = true
            API.instance.post(`/radius/detectpool`, {ip_address: this.selectedPPPOEUser['Framed-IP-Address']})
                .then(async (response) => {
                    if (response.data.pool_id) {
                        this.selectedIPPool = response.data.pool_id
                        await this.onIpPoolChange()
                        await this.getUcrmContractInfo()
                        this.poolFreeIPS.push(this.selectedPPPOEUser['Framed-IP-Address'])
                        this.selectedIP = this.selectedPPPOEUser['Framed-IP-Address']
                    }
                    else {
                        this.$store.dispatch("messages/addText", "Nincs pool ehhez az IP tartományhoz.")
                    }
                })
                .catch((err) => {this.$store.dispatch('messages/addText', err)})
                .finally(() => this.loading = false)
        },
        onSubmit: function(){
            this.loading = true
            API.instance.patch(`/radius/user/${this.selectedPPPOEUser.Username}`, {attribute: "Framed-IP-Address", value: this.selectedIP})
                .then(() => {
                    this.snackbarText = 'IP cím módosítva.'
                    this.snackbar = true
                })
                .catch((err) => {this.$store.dispatch('messages/addText', err)})
                .finally(() => this.loading = false)
        },
    }
}
</script>